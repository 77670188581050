
import axios from 'axios';
export default {
    
    async createSections(params)  {
        return await axios.post(`sections/create.php` , params)
    },
    async getAllSections()  {
        return await axios.get(`sections/readAll.php`)
    },
    async getOneSections(section_id)  {
        return await axios.get(`sections/readById.php?section_id=${section_id}`)
    },
    async getSectionsByColumn(column , value)  {
        return await axios.get(`sections/readByColumn.php?column=${column}&value=${value}`)
    },
    async deleteSections(section_id)  {
        return await axios.post(`sections/delete.php?section_id=${section_id}`)
    },
    async updateSections(section_id , params)  {
        return await axios.post(`sections/update.php?section_id=${section_id}` , params)
    }
}