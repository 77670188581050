<template>
    <div>
        <v-container class="my-2">
            <v-layout row wrap px-0 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.sections.list_path">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                {{ item.title.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3>

                </v-flex>
            </v-layout>
            <form @submit.prevent="addSections" autocomplete="off">
                <v-layout row wrap>

                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-text-field v-model="sections.section_name_ku" type="text"
                            :label="$store.getters.language.data.sections.section_name_ku" dense class="mx-1" filled
                            outlined required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-text-field v-model="sections.section_name_en" type="text"
                            :label="$store.getters.language.data.sections.section_name_en" dense class="mx-1" filled
                            outlined required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-text-field v-model="sections.section_name_ar" type="text"
                            :label="$store.getters.language.data.sections.section_name_ar" dense class="mx-1" filled
                            outlined required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-textarea auto-grow rows="3" v-model="sections.section_text_ku" type="textarea"
                            :label="$store.getters.language.data.sections.section_text_ku" dense class="mx-1" filled
                            outlined>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-textarea auto-grow rows="3" v-model="sections.section_text_en" type="textarea"
                            :label="$store.getters.language.data.sections.section_text_en" dense class="mx-1" filled
                            outlined>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-textarea auto-grow rows="3" v-model="sections.section_text_ar" type="textarea"
                            :label="$store.getters.language.data.sections.section_text_ar" dense class="mx-1" filled
                            outlined>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-textarea auto-grow rows="3" v-model="sections.section_description_ku" type="textarea"
                            :label="$store.getters.language.data.sections.section_description_ku" dense class="mx-1"
                            filled outlined>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-textarea auto-grow rows="3" v-model="sections.section_description_ar" type="textarea"
                            :label="$store.getters.language.data.sections.section_description_ar" dense class="mx-1"
                            filled outlined>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md3 sm4>
                        <v-textarea auto-grow rows="3" v-model="sections.section_description_en" type="textarea"
                            :label="$store.getters.language.data.sections.section_description_en" dense class="mx-1"
                            filled outlined>
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="sections.section_index" type="number"
                            :label="$store.getters.language.data.sections.section_index" dense class="mx-1" filled
                            outlined required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable :items="['home','sections','disabled']"
                            v-model="sections.section_status" dense filled outlined item-text="section_status"
                            item-value="section_status" :return-object="false"
                            :label="$store.getters.language.data.sections.section_status">
                        </v-select>
                    </v-flex>

                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn color="primary" :loading="loading_btn" type="submit">
                            {{$store.getters.language.data.sections.add_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>

        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import requests from './../../requests/sections.request.js'
    export default {
        data() {
            return {
                sections: {},
                search: '',
                loading: true,
                loading_btn: false,

                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            page() {
                const pageKey = 'SECTIONS'
                return {}
            }
        },
        mounted() {},
        methods: {

            addSections() {
                this.loading_btn = true
                requests.createSections(this.sections).then(r => {
                        if (r.status == 200 && r.data.status == 'ok') {
                            this.sections = {}

                            this.snackbar = {
                                value: true,
                                text: 'Sections Added',
                                color: 'success'
                            }
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to add Sections',
                                color: 'error'
                            }
                        }
                    })
                    .finally(() => {
                        this.loading_btn = false
                    })

            },

        },
    }
</script>